import keyBy from 'lodash-es/keyBy';
import last from 'lodash-es/last';
import chunk from 'lodash-es/chunk';
import Item from './components/Item';
import MemorizeItem from './components/MemorizeItem';
import PracticeConfiguration from './components/PracticeConfiguration';
import backgroundImage from './images/background.png';

import dataRaw from './data/a-home';
const itemsRaw = dataRaw.items;

export default function generateConfig({ language }) {
    const items = itemsRaw.map((rawItem, i) => {
        return {
            id: `${dataRaw.id}:${i + 1}`,
            word: rawItem[language].text,
            label: `${i + 1}`,
            name: `${dataRaw[language].name}`,
            image: rawItem.img,
            stepNumber: i + 1
        };
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        itemGroups: chunk(items, 20).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].stepNumber}-${last(sublist).stepNumber}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        PracticeConfiguration,
        backgroundImage,
        systemId: 'journey',

        getImagesInGroup(group) {
            return group.itemIds.map(itemId => itemsMap[itemId].image);
        }
    };
}
