<template>
  <div class="PracticeConfiguration">
    <div class="loremIpsum">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
      ea commodo consequat.
    </div>
    <br>
    <GameSelectionSection
      :game-ids="gameIds"
      :selected-game-id="selectedGame"
      @game-selected="gameId => (selectedGame = gameId)" />
  </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';
import gameHelper from '@/modules/games-shared/game-helper';
import GameSelectionSection from '@/modules/systems-shared/components/GameSelectionSection';

export default {
    components: { GameSelectionSection },
    inject: ['config', 'module'],
    data() {
        return {
            selectedNumbers: [],
            selectedGame: null,
            gameIds: ['shopping', 'bankvault']
        };
    },
    computed: {
        isReady() {
            return !!this.selectedGame;
        }
    },
    watch: {
        isReady: {
            handler: function (val) {
                this.$emit('ready-status-changed', val);
            },
            immediate: true
        }
    },
    methods: {
        onSelectedNumbersChanged(numbers) {
            this.selectedNumbers = numbers;
        },
        selectGame(gameId) {
            this.selectedGame = gameId;
        },
        getGameSessionOptionsForShopping() {
            return {
                numberOfItems: 10,
                itemMemorizeTimeLimit: 30,
                itemQuizTimeLimit: 30
            };
        },
        getGameSessionOptionsForBankvault() {
            const randomDigits = shuffle('0123456789'.split(''));
            return {
                items: this.config.itemIds.map((x, i) => ({
                    ring1: randomDigits[i % 10]
                })),
                itemMemorizeTimeLimit: 30,
                itemQuizTimeLimit: 30,
                chunks: this.config.itemIds.length
            };
        },
        getGameSessionOptions() {
            if (this.selectedGame === 'shopping') {
                return this.getGameSessionOptionsForShopping();
            } else if (this.selectedGame === 'bankvault') {
                return this.getGameSessionOptionsForBankvault();
            }
        },
        startPractice() {
            // NOTE: used from parent component
            if (!this.isReady) {
                return;
            }

            gameHelper.createPracticeSession({
                gameId: this.selectedGame,
                exitUrl: this.$route.path,
                sessionOptions: this.getGameSessionOptions(),
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.PracticeConfiguration {
    display: flex;
    flex-direction: column;
}
.loremIpsum {
    padding: 2em;
    color: rgba(white, 0.8);
    line-height: 1.5em;
}
</style>
