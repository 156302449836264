const images = require.context('../images/items/a-home', true, /\.(png|jpg)$/);

function getImageUrl(path) {
    try {
        return images(`./${path}`);
    } catch (err) {
        return null;
    }
}

export default {
    id: 'a-home',
    en: {
        name: 'A home'
    },
    no: {
        name: 'Et hjem'
    },

    items: [
        { img: getImageUrl('Bed.png'), en: { text: 'Bed' }, no: { text: 'Seng' } },
        { img: getImageUrl('Bedroom-Floor.png'), en: { text: 'Bedroom floor' }, no: { text: 'Soveromsgulv' } },
        { img: getImageUrl('Hallway.png'), en: { text: 'Hallway' }, no: { text: 'Gang' } },
        { img: getImageUrl('Toilet.png'), en: { text: 'Toalett' }, no: { text: 'Toalett' } },
        { img: getImageUrl('Shower.png'), en: { text: 'Shower' }, no: { text: 'Dusj' } },
        { img: getImageUrl('Living-Room.png'), en: { text: 'Living room' }, no: { text: 'Stue' } },
        { img: getImageUrl('Dining-Table.png'), en: { text: 'Dining table' }, no: { text: 'Stuebord' } },
        { img: getImageUrl('Kitchen.png'), en: { text: 'Kitchen' }, no: { text: 'Kjøkken' } },
        { img: getImageUrl('Exit-door.png'), en: { text: 'Exit' }, no: { text: 'Husdør' } },
        { img: getImageUrl('Driveway.png'), en: { text: 'Driveway' }, no: { text: 'Innkjørsel' } }
    ]
};
